.Widgets {
  position: relative;
  z-index: 2001;
  pointer-events: all;

  .float-left {
    position: absolute;
    left: 0;
  }

  .float-right {
    position: absolute;
    right: 0;
  }
}
