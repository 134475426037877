$clientColor: #4a5e8f;

.ItemsSection {
  .imageItems {
    border-radius: 100px;
    cursor: pointer;
    width: 85px;

    &.colors {
      border-radius: initial;
    }
  }

  button {
    width: max-content;
    background-color: #f5f6f4;
    border-radius: 6px;
    border: none;
    box-shadow: 0 5px 5px 2px #8c8c8c;
    color: $clientColor;
    padding: 5px 10px 5px 10px;
  }
}
