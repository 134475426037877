.customCanvas {
  user-select: none;
  pointer-events: none;

  // TO SEE THE CANVAS FOR TESTING
  display: none;
  // position: absolute;
  // top: 0;
  // left: 50px;
  // z-index: 1000000000000;
  // background-color: black;
}
