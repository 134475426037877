.maintenance {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 24px;
    color: #5e5e5e;
  }
}
