.BuilderScreen {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
  overflow: hidden;
}

.shared-scene-sizing {
  position: absolute;
  top: 0;
  z-index: 1001;

  height: 100% !important;
  // this needs to match width of BuildSection styles
  width: calc(100vw - 450px) !important;
  margin-left: 450px !important;

  @media (orientation: portrait) {
    height: calc(100% - 55%) !important;
    width: 100% !important;
    margin-left: 0px !important;
  }

  // contains the 3D scene
  &.builder-scene-canvas-container {
    position: absolute !important;
    top: 0;
    cursor: grab;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    @media (orientation: portrait) {
      color: #5e5e5e;
      box-shadow: 0px 0px 10px 1px #5e5e5e;
    }
  }
}
