$sm: 425px !default;
$md: 850px !default;
$lg: 992px !default;

$bg: rgb(250, 250, 255); // background (flat surface)
$fg: #333; // loading text
$fg-t: #333; // loading text
$primary1: white; // main color on raised dots
$primary2: #314577; // secondary color on raised dots
$trans-dur: 0.3s;
$hue: 223;
// $ringwidth: 500px;
// $dotswidth: calc($ringwidth / 2 - 200%);
.sceneLoadingScreen {
  background-color: $bg;
  // background-image: linear-gradient(135deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  color: $fg;
  font: 1em/1.5 Helvetica, sans-serif;
  display: grid;
  place-items: center;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10000;
  pointer-events: auto;

  @media (max-width: $md), (orientation: portrait) {
    box-shadow: 50px 50px 200px rgba(255, 166, 0, 0.01) inset;
  }
  box-shadow: 100px 100px 200px rgba(255, 166, 0, 0.03) inset;

  // img {
  //   @media (max-width: $md), (orientation: portrait) {
  //     width: 100%;
  //   }
  //   width: 50%;
  // }

  // 3D loader
  .pl {
    box-shadow: 2em 0 2em rgb(77 80 131 / 20%) inset, -2em 0 2em rgb(197 111 46 / 10%) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    transform: rotateX(30deg) rotateZ(45deg);

    width: 500px;
    height: 500px;

    @media screen and (max-width: $lg) {
      width: 420px;
      height: 420px;
    }
    @media screen and (max-width: $md) {
      width: 350px;
      height: 350px;
    }
    @media screen and (max-width: $sm) {
      width: 300px;
      height: 300px;
    }

    .content-container {
      transform: rotateZ(-45deg) translate(0px, 21px);
    }

    .loadingLogoContainer {
      max-width: calc(500px - 90%);
      min-height: 80px;
      @media screen and (max-width: $lg) {
        max-width: calc(420px - 90%);
        min-height: 60px;
      }
      @media screen and (max-width: $md) {
        max-width: calc(350px - 75%);
        min-height: 55px;
      }
      @media screen and (max-width: $sm) {
        max-width: calc(300px - 55%);
        min-height: 52px;
      }

      .loadingLogo {
        width: 100%;
        margin-bottom: 10px;
        opacity: 0;
        transition: opacity 0.5s;
        &.loaded {
          opacity: 1;
        }
      }
    }

    &,
    &__dot {
      border-radius: 50%;
    }
    &__dot {
      $dur: 2s;
      // animation-name: shadow;
      box-shadow: 0.1em 0.1em 0 0.1em hsl(0, 0%, 0%), 0.3em 0 0.3em hsla(0, 0%, 0%, 0.5);
      top: calc(50% - 0.75em);
      left: calc(50% - 0.75em);
      width: 1.5em;
      height: 1.5em;

      &,
      &:before,
      &:after {
        animation-duration: $dur;
        animation-iteration-count: infinite;
        position: absolute;
      }
      &:before,
      &:after {
        content: "";
        display: block;
        left: 0;
        width: inherit;
        transition: background-color $trans-dur;
      }
      &:before {
        animation-name: pushInOut1;
        background-color: $primary1;
        // background-color: $bg;
        border-radius: inherit;
        box-shadow: 0.05em 0 0.1em hsla(0, 0%, 100%, 0.2) inset;
        height: inherit;
        z-index: 1;
      }
      &:after {
        // animation-name: pushInOut2; // HAYDEN removed because this glitches looks bad when 3D scene loads
        background-color: $primary2;
        border-radius: 0.75em;
        box-shadow: -2px -2px 10px #999 inset;
        // box-shadow: 0.1em 0.3em 0.2em hsla(0,0%,100%,0.4) inset, 0 (-0.4em) 0.2em hsl($hue,10%,20%) inset, 0 (-1em) 0.25em hsla(0,0%,0%,0.3) inset;
        bottom: 0;
        // clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
        height: 23px;
        transform: rotate(-45deg);
        // transform-origin: 50% 2.25em;
      }
      $dots: 12;
      $zIndices: (5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 6);

      @for $i from 1 through $dots {
        &:nth-child(#{$i}) {
          $angle: calc(360deg / $dots * ($i - 1));
          z-index: nth($zIndices, $i);
          transform: rotate(-$angle) translateX(calc(500px / 2 - 200%)) rotate($angle);
          @media screen and (max-width: $lg) {
            transform: rotate(-$angle) translateX(calc(420px / 2 - 200%)) rotate($angle);
          }
          @media screen and (max-width: $md) {
            transform: rotate(-$angle) translateX(calc(350px / 2 - 200%)) rotate($angle);
          }
          @media screen and (max-width: $sm) {
            transform: rotate(-$angle) translateX(calc(300px / 2 - 100%)) rotate($angle);
          }

          &,
          &:before,
          &:after {
            animation-delay: -$dur * calc(($i - 1) / $dots);
          }
        }
      }
    }
    &__text {
      font-size: 0.75em;
      max-width: 5rem;
      position: relative;
      text-shadow: 0 0 0.1em $fg-t;
      // transform: rotateZ(-45deg);
    }
  } /* Animations */
  @keyframes shadow {
    from {
      animation-timing-function: ease-in;
      box-shadow: 0.1em 0.1em 0 0.1em hsl(0, 0%, 0%), 0.3em 0 0.3em hsla(0, 0%, 0%, 0.3);
    }
    25% {
      animation-timing-function: ease-out;
      box-shadow: 0.1em 0.1em 0 0.1em hsl(0, 0%, 0%), 0.8em 0 0.8em hsla(0, 0%, 0%, 0.5);
    }
    50%,
    to {
      box-shadow: 0.1em 0.1em 0 0.1em hsl(0, 0%, 0%), 0.3em 0 0.3em hsla(0, 0%, 0%, 0.3);
    }
  }

  @keyframes pushInOut1 {
    from {
      animation-timing-function: ease-in;
      // background-color: $bg;
      transform: translate(0, 0);
    }
    25% {
      animation-timing-function: ease-out;
      // background-color: $primary2;
      transform: translate(-21%, -21%);
      // transform: translate(-71%,-71%);
    }
    50%,
    to {
      // background-color: $bg;
      transform: translate(0, 0);
    }
  }

  @keyframes pushInOut2 {
    from {
      animation-timing-function: ease-in;
      background-color: $bg;
      clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
    }
    25% {
      animation-timing-function: ease-out;
      background-color: $primary1;
      clip-path: polygon(0 25%, 100% 25%, 100% 100%, 0 100%);
    }
    50%,
    to {
      background-color: $bg;
      clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
    }
  }
}
