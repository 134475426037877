$clientColor: #4a5e8f;

.modal-dialog {
  .modal-header {
    background-color: $clientColor;
    color: white;
  }

  p,
  h3,
  h4 {
    color: $clientColor;
    text-align: center;
  }

  input {
    height: 15px;
    width: 15px;

    &.form-check-input:checked {
      background-color: $clientColor;
      border-color: $clientColor;
    }
  }

  textarea {
    border-radius: 6px;
    color: $clientColor;
    outline: none;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
  }

  button {
    background-color: $clientColor;
    color: white;
    box-shadow: 0px 1px 2px #888888;
    border: none;
    border-radius: 4px;
    &:hover {
      background-color: $clientColor;
    }
  }

  .submitBtn:disabled {
    background-color: #b5b4b4;
    &:hover {
      background-color: #b5b4b4;
    }
  }
}
