@import "toastr";

$clientColor: #4a5e8f;
$body-color: #424345;

:root {
  --muted: #c9c9c9;
  --border-muted: #eee;
}

@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@700&family=Roboto:wght@900&display=swap");

html {
  height: 100vh;
  height: -webkit-fill-available;
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
  cursor: auto !important;
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
.btn {
  font-family: "Roboto", sans-serif;
  user-select: text;
}

// dat.gui debug
.dg.ac {
  z-index: 1;
}

.activeDisplayName {
  color: $clientColor;
  text-shadow: 1px 1px 4px#999;
}

#react-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .price {
    color: #028513;
    border-radius: 3px 8px 3px 3px;
    background-color: #e9edf0;
  }

  .activeImage {
    border: 2px dotted $clientColor;
    border-radius: 8px;
    padding: 1px;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  .active,
  .activeText,
  .hover_effect:hover {
    background-color: rgba(74, 94, 143, 0.1);
    border-style: solid;
    border-color: $clientColor;
    color: $clientColor !important;
  }

  .checkMark {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    left: -10px;
    top: -10px;
    width: 22px;
    // mobile
    @media screen and (orientation: landscape) {
      left: -10px;
      top: -10px;
      width: 22px;
    }
  }

  .description {
    font-size: 12px;
    font-style: italic;
  }
}

.displayNone {
  display: none;
}

.MuiLinearProgress-root {
  width: 100% !important;
  background: linear-gradient(90deg, $clientColor 0%, $body-color 53%, $clientColor 100%);
}

.MuiLinearProgress-barColorPrimary {
  background-color: #000000 !important;
}

.toast-info {
  background-color: #4a5e8f !important;
}

// ***************
// fonts for Bradley text embroidery
// ***************

@font-face {
  font-family: "brush_script";
  src: url("/fonts/brush_script/BrushScriptStd.woff2") format("woff2"), url("/fonts/brush_script/BrushScriptStd.woff") format("woff"),
    url("/fonts/brush_script/BrushScriptStd.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "script_mt_bold";
  src: url("/fonts/script_mt_bold/ScriptMTBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bradley_block";
  src: url("/fonts/bradley_block/BRADLEYBLOCK.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
