.InputBox {
  .form-group {
    .label {
      color: #727272;
    }
    .form-control,
    .custom-select {
      background-color: white;
      border-radius: 8px;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    }
  }

  .input-container {
    position: relative;

    input {
      border: none;
      display: block;
      width: 100%;
    }
    // remove the default arrows for number input
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    img {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
