.rectangleBtn {
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 4px 4px;
  width: 100%;
  border: none;
  user-select: none;
  color: white;
  background-color: #4a5e8f;

  &.disabled {
    opacity: 0.5;
  }

  img {
    width: 25px;
    margin-left: 5px;
  }
}
