$clientColor: #4a5e8f;

.accordion_item {
  padding: 13px 10px;
  overflow: hidden;
  border-style: solid;
  border-color: #f5f6f4;
  cursor: pointer;
  background-color: #f5f6f4;
  box-shadow: 0 1px 10px 3px #8c8c8c;
  border-radius: 8px;

  &.parent {
    width: 100%;
  }
  &.nested {
    width: 90%;
    box-shadow: initial;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    padding: 10px 10px;
  }
}
